import React from "react";
import { Row, Col } from "react-bootstrap";
import sales_report_pie_chart from ".././CLIKResources/sales_report_pie_chart.png";
import sales_report_line_chart from ".././CLIKResources/sales_report_line_chart.png";
import { FaChartPie, FaRegCopy } from "react-icons/fa";

interface SalesReportProps {
  handleCopyActionComplete(): void;
}

const salesSummaryText =
  "Lead Conversion Rate: 18:1\nLeads: 2,536\nOpportunities: 537\nNegotiation: 295\nWins: 138";

const SalesReport = ({ handleCopyActionComplete }: SalesReportProps) => {
  const salesSummaryTextElement = document.getElementById(
    "sales-summary-text"
  ) as HTMLInputElement;

  // if user copies text by clicking the copy button
  const handleCopyText = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    handleCopyActionComplete();
    if (salesSummaryTextElement) {
      navigator.clipboard.writeText(salesSummaryText);
      e.preventDefault();
    }
  };

  // if user copies text using system mouse and keyboard shortcuts
  if (salesSummaryTextElement) {
    salesSummaryTextElement.addEventListener("copy", event => {
      const selection = document.getSelection();
      if (event && event.clipboardData && selection) {
        event.clipboardData.setData("text/plain", selection.toString());
        event.preventDefault();
        if (event.clipboardData.getData("text/plain") === salesSummaryText) {
          handleCopyActionComplete();
        }
      }
    });
  }

  return (
    <>
      <div
        style={{
          backgroundColor: "black",
          color: "white",
          height: "30px",
          lineHeight: "30px",
          paddingLeft: "16px"
        }}
      >
        <FaChartPie
          color="#00a9e0"
          style={{
            marginLeft: "3px",
            marginRight: "3px",
            position: "relative",
            bottom: "1px"
          }}
        />
        Sales Report
      </div>
      <Row
        noGutters={true}
        style={{
          margin: "20px 10px 0px 10px",
          justifyContent: "space-around",
          flexWrap: "nowrap"
        }}
      >
        <Col
          xl={4}
          lg={4}
          md={4}
          sm={4}
          xs={4}
          style={{
            backgroundColor: "#edeeee",
            borderRadius: "4px",
            border: "1px solid #cbcccd",
            padding: "10px",
            margin: "10px",
            maxWidth: "fit-content"
          }}
        >
          <div
            style={{
              display: "flex",
              fontSize: "14px"
            }}
          >
            <span style={{ fontWeight: "bold" }}>Sales Summary</span>
            <button onClick={handleCopyText} style={{ color: "#425cc7" }}>
              <FaRegCopy color="#425cc" />
              Copy
            </button>
          </div>
          <div
            style={{
              backgroundColor: "#edeeee",
              border: "none",
              fontSize: "12px",
              resize: "none",
              width: "100%",
              height: "90px",
              lineHeight: "18px",
              outline: "0"
            }}
          >
            <span id="sales-summary-text" style={{ whiteSpace: "pre-line" }}>
              {salesSummaryText}
            </span>
          </div>
        </Col>
        <Col xl={5} lg={5} md={5} sm={5} xs={5} style={{ textAlign: "center" }}>
          <img src={sales_report_line_chart} alt="sales report line chart" />
        </Col>
        <Col xl={3} lg={3} md={3} sm={3} xs={3} style={{ textAlign: "center" }}>
          <img src={sales_report_pie_chart} alt="sales report pie chart" />
        </Col>
      </Row>
    </>
  );
};

export default SalesReport;
