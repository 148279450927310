import { useEffect, useState } from "react";
import { EventData } from "../Interfaces/EventData";
import { SubTestResumeData } from "../Interfaces/SubTestData";
import {
  apiURL,
  CLIK_SIMULATION_SECTIONS,
  CLIK_SIMULATION_TIME_ALLOWED,
  sskowk
} from "../utils/constants";
import Encryption from "../utils/Encryption";
import { getTraditionalTestResumeMethod } from "../utils/redisHelpers";
import { ErrorMessage } from "../utils/Reference/ErrorMessage";
import { Hook } from "../utils/Reference/Hook";
import { TestType } from "../utils/Reference/TestType";

type ExtendedEventData = EventData & {
  lastQuestionNumber: number;
  pageNumberToResume: number;
  startingTaskNumber: number;
  startingSection: number;
  startingQuestion: number;
};

type GetDataProps = {
  eventId: string | null;
  subTestId: string;
  testType: string;
};

type GetDataResult = {
  data: ExtendedEventData | null;
  isLoading: boolean;
  error: Error | null;
};

const useGetData: Hook<GetDataProps, GetDataResult> = ({
  eventId,
  subTestId,
  testType
}) => {
  const [data, setData] = useState<GetDataResult["data"]>(null);
  const [error, setError] = useState<GetDataResult["error"]>(null);

  useEffect(() => {
    async function getData() {
      try {
        if (!eventId) {
          throw new Error(ErrorMessage.EVENT_ID_NOT_PROVIDED);
        }
        const response = await fetch(`${apiURL}/event/${eventId}`);
        if (!response.ok) {
          throw new Error(
            response.status > 499
              ? ErrorMessage.INTERNAL_SERVER_ERROR
              : ErrorMessage.NOT_FOUND
          );
        }
        const data = (await response.json()) as { data: EventData };
        if (!("data" in data)) {
          throw new Error(ErrorMessage.INTERNAL_SERVER_ERROR);
        }
        const encryption = new Encryption();
        const decryptedData = encryption.decrypt(data.data, sskowk);
        const parsedData = JSON.parse(decryptedData) as EventData;
        const resumeData = await setResumeData(testType, subTestId, parsedData);
        setData(() => resumeData);
      } catch (e) {
        console.error(e);
        e instanceof Error
          ? setError(e)
          : setError(new Error(ErrorMessage.INTERNAL_SERVER_ERROR));
      }
    }
    getData();
  }, [subTestId, eventId, testType]);

  return {
    data,
    isLoading: !data,
    error
  };
};

async function setResumeData(
  testType: string,
  subTestId: string,
  data: EventData
): Promise<ExtendedEventData> {
  if (!data.tests[subTestId]) {
    throw new Error(ErrorMessage.NOT_FOUND);
  }
  const resumeMethod = getTraditionalTestResumeMethod(testType);
  if (!resumeMethod) {
    return {
      ...data,
      lastQuestionNumber: 0,
      pageNumberToResume: 1,
      startingTaskNumber: 1,
      startingSection: 1,
      startingQuestion: 0
    };
  }
  const resumeData = await resumeMethod(
    data.testEventId,
    subTestId,
    data.token
  );
  data.timeFactor = data.timeFactor ?? 1;
  data.tests[subTestId].details.timeAllowed = getResumeTime(
    testType,
    data.tests[subTestId].details.timeAllowed,
    data.timeFactor,
    resumeData
  );
  return {
    ...data,
    lastQuestionNumber: resumeData?.questionNumber ?? 0,
    pageNumberToResume: resumeData?.pageNumberToResume ?? 1,
    startingTaskNumber: resumeData?.startingTaskNumber ?? 1,
    startingSection: resumeData?.startingSection ?? 1,
    startingQuestion: resumeData?.startingQuestion ?? 0
  };
}

function getResumeTime(
  testType: string,
  timeAllowed: number,
  timeFactor: number,
  resumeData: SubTestResumeData
) {
  if (testType === TestType.CLIK) {
    return (
      timeAllowed -
      CLIK_SIMULATION_TIME_ALLOWED * timeFactor * CLIK_SIMULATION_SECTIONS -
      Number(resumeData.expiredTime ?? 0)
    );
  } else {
    return timeAllowed - Number(resumeData.expiredTime ?? 0);
  }
}

export default useGetData;
