import React, { FunctionComponent, useContext, useState } from "react";
import { TestEventContext } from "../Contexts/TestEventContext";
import useGetData from "../CustomHooks/useGetData";
import useGetToken from "../CustomHooks/useGetToken";
import CLIKTestV2 from "../Tests/TestDisplays/CLIKTestV2/CLIKTestV2";
import MrabCast from "../Tests/TestDisplays/Mrab/MrabCast";
import MultipleChoiceTest from "../Tests/TestDisplays/MultipleChoice/MultipleChoiceTest";
import PersonalityTest from "../Tests/TestDisplays/PersonalityTest";
import TenKeyTests from "../Tests/TestDisplays/TenKeyTests";
import TypingTest from "../Tests/TestDisplays/TypingTest";
import { getAllTests } from "../utils/Reference/SubTestDataMaps";
import { TestType } from "../utils/Reference/TestType";
import { CLIK_SIMULATION_TIME_ALLOWED } from "../utils/constants";

const Direct: FunctionComponent = () => {
  const { subTestId, eventId, returnUrl } = useGetToken();
  const testLibrary = getAllTests();
  const testType = testLibrary[subTestId];
  const [isComplete, setIsComplete] = useState(false);
  const { data, isLoading, error } = useGetData({
    eventId,
    subTestId,
    testType
  });
  const testEventContext = useContext(TestEventContext);

  const endTestHandler = () => {
    setIsComplete(() => true);
    window.location.replace(returnUrl);
  };

  if (error) {
    // TODO - determine if we want <ErrorPage /> here
    return error instanceof Error ? (
      <div>
        {error.name} : {error.message}
      </div>
    ) : (
      <div>Unknown Error</div>
    );
  }

  if (isLoading || isComplete) {
    return <div>Loading...</div>;
  }

  if (data) {
    testEventContext.testEventData = data;
    testEventContext.eventId = data.eventId;
    testEventContext.testEventId = data.testEventId;
    testEventContext.token = data.token;

    return (
      <div
        style={{
          minHeight: "100vh",
          height: "100%",
          backgroundColor: "#FFF",
          margin: "-16px",
          padding: "16px"
        }}
      >
        {determineAssessment(testType, {
          testData: data.tests[subTestId].details,
          subTestId,
          startingQuestion: data.startingQuestion
            ? data.startingQuestion
            : data.lastQuestionNumber,
          pageNumberToResume: data.pageNumberToResume,
          startingSection: data.startingSection,
          timeFactor: data.timeFactor,
          timeRemaining: data.tests[subTestId].details.timeAllowed,
          startingTaskNumber: data.startingTaskNumber,
          dataLoaded: true,
          endSubTest: endTestHandler,
          testEventId: data.testEventId
        })}
      </div>
    );
  }

  return <div>Error!</div>;
};

function determineAssessment(
  testType: string,
  props: {
    testEventId: number;
    subTestId: string;
    timeRemaining: number;
    timeFactor: number;
    dataLoaded: boolean;
    testData: any;
    endSubTest: () => void;
    startingQuestion: number;
    startingSection: number;
    startingTaskNumber: number;
    simulationTimeAllowed?: number;
    isCLIKv2?: boolean;
    pageNumberToResume?: number;
  }
): JSX.Element {
  switch (testType) {
    case TestType.MULTIPLE_CHOICE:
      return <MultipleChoiceTest {...props} />;
    case TestType.PERSONALITY:
      return <PersonalityTest {...props} />;
    case TestType.MRAB_CAST:
      return <MrabCast {...props} />;
    case TestType.CLIK:
      return (
        <CLIKTestV2
          {...props}
          handleVisibilityChange={() => {}}
          originalSimulationTimeAllowed={CLIK_SIMULATION_TIME_ALLOWED}
        />
      );
    case TestType.TYPING:
      return (
        <TypingTest
          {...props}
          testMakerTestId=""
          timeAllowed={60}
          title="Criteria Typing Test"
        />
      );
    case TestType.TEN_KEY:
      return <TenKeyTests {...props} />;
    default:
      return <div>Error!</div>;
  }
}

export default Direct;
